// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import { GatsbyImage, getImage, type IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import Button from "~components/common/Button";
import SubTitle from "~components/typography/SubTitle";
import H1 from "~components/typography/H2";
import P from "~components/typography/P";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import Blockquote from "~components/typography/Blockquote";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	profileImage: IGatsbyImageData;
	imageBanner: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const banner = getImage(data.imageBanner);

	return (
		<Layout
			location={location}
			title="Keynote-Speaker & Moderation – Impulse für die Zukunft"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero image={data.imageHeader} alt="Coaching mit Daniel Thiel">
				<SubTitle className="mb-2" as="h1">
					Keynote-Speaker & Moderation – Impulse für die Zukunft
				</SubTitle>
				<H1 as="h2" display className="mb-4">
					Inspirierende Vorträge für Unternehmen, Events & Kongresse{" "}
				</H1>
				<P className="mb-4 ">
					Als zertifizierter und professioneller <b>Keynote-Speaker</b> mit Ausbildung durch die <b>Greator GmbH</b>{" "}
					sowie Expertise durch Deutschlands führenden Mentaltrainer <b>Oliver Geisselhart</b> halte ich inspirierende
					Vorträge zu essenziellen Zukunftsthemen:
				</P>
				<P>Meine Themen:</P>
				<Ul
					items={[
						<>
							<b>Speed Reading – Leaders are Readers</b>
							<br />
							Wie Führungskräfte und Mitarbeiter im Zeitalter von KI, ihre mentale Verarbeitungsgeschwindigkeit
							revolutionieren
						</>,
						<>
							<b>Mentale Transformation für Führungskräfte in Zeiten von KI</b>
							<br />
							Wie sich Führungskräfte in der Ära der künstlichen Intelligenz mental neu aufstellen und Höchstleistungen
							abrufen
						</>,
						<>
							<b>So entfalten Eishockeyspieler ihr volles Potential</b>
							<br />
							Praktische Strategien für mentale Klarheit, Fokus und Umsetzungskraft im Sport
						</>,
					]}
					className="mb-4"
					pretty
				></Ul>

				<H1 as="h2" display className="mb-4">
					Moderation für Business-Events & Galas
				</H1>
				<Ul
					items={[
						"Professionelle Moderation für Firmenveranstaltungen, Messen und Gala-Abende",
						"Langjährige Erfahrung als Speaker und Moderator für hochkarätige Events",
					]}
					className="mb-4"
					pretty
				></Ul>

				<Button to="/speaker/anfragen/" text="Termin vereinbaren" />
			</Hero>
			<Container>
				<Grid cols={2}>
					<div>{banner ? <GatsbyImage image={banner} alt="" className="rounded" /> : null}</div>
					<div className="prose">
						<h2>Daniel Thiel &ndash; Keynote-Speaker &amp; Redner f&uuml;r begeisternde Events</h2>
						<p>
							Du suchst einen Redner, der dein Publikum fesselt, inspiriert und nachhaltige Impulse setzt? Dann bist du
							bei mir genau richtig!
						</p>
						<p>
							Keynote: "Speed Reading &ndash; Leaders are Readers" Ich zeige Unternehmern, F&uuml;hrungskr&auml;ften und
							High Performern, wie sie ihre Lesegeschwindigkeit verdoppeln, Wissen schneller aufnehmen und produktiver
							werden. In Zeiten von KI brauchen auch wir ein kognitives Upgrade &ndash; und ich liefere die Strategie
							daf&uuml;r. Begeisternd, interaktiv, praxisnah!
						</p>
						<p>
							Mit meiner Keynote &bdquo;So entfalten Eishockeyspieler ihr volles Potenzial&ldquo; zeige ich, wie
							Profisportler durch Mentaltraining, Hypnose und Selbstvertrauen ihre Leistung auf ein neues Level heben.
							Mein Wissen basiert auf fundierter Erfahrung aus der Welt des Hochleistungssports, Business Coaching und
							Hypnose-Coaching sowie meiner Arbeit mit Athleten, die ihre mentale St&auml;rke maximieren wollen.
						</p>
						<p>Das erwartet dein Publikum:</p>
						<ul>
							<li>Spannende Impulse &ndash; Wissenschaftlich fundiert, praxisnah &amp; sofort umsetzbar</li>
							<li>Dynamischer Vortragsstil &ndash; Mitrei&szlig;end, interaktiv, voller Energie und charmant</li>
							<li>Direkter Mehrwert &ndash; Techniken, die die Teilnehmer sofort anwenden k&ouml;nnen</li>
							<li>Nachhaltige Wirkung &ndash; Motivation und Transformation statt trockener Theorie</li>
						</ul>
						<p>Lass uns gemeinsam dein Event unvergesslich machen! Ich freue mich auf den Austausch mit dir.</p>
						<p>
							Beste Gr&uuml;&szlig;e,
							<br /> Daniel Thiel
						</p>
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeader: file(relativePath: { eq: "pages/speaker/speaker_header.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageBanner: file(relativePath: { eq: "pages/speaker/speaker_banner.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
